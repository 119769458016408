import React from 'react';

import Icon from './Icon';

export default function ShareIcon({ className = '' }: { className?: string; }): JSX.Element {
  return (
    <Icon text="Share">
      <svg
        className={className}
        width="20"
        height="21"
        viewBox="0 0 20 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.52761 3.18504C9.80311 2.96163 10.1974 2.96177 10.4728 3.18537C11.7785 4.24567 12.4701 4.94003 13.5797 6.2917C13.8425 6.61186 13.796 7.08445 13.4759 7.34727C13.1557 7.61009 12.6831 7.56361 12.4203 7.24345C11.7823 6.46624 11.3027 5.93159 10.75 5.40868V13.2676C10.75 13.6818 10.4142 14.0176 10 14.0176C9.58579 14.0176 9.25 13.6818 9.25 13.2676V5.40764C8.69738 5.93024 8.21792 6.46512 7.57995 7.24314C7.31731 7.56344 6.84474 7.61017 6.52444 7.34753C6.20414 7.08488 6.1574 6.61232 6.42005 6.29202C7.52978 4.93869 8.22161 4.2441 9.52761 3.18504ZM3 13.0176C3.41421 13.0176 3.75 13.3534 3.75 13.7676V14.7676C3.75 16.0102 4.75736 17.0176 6 17.0176H14C15.2426 17.0176 16.25 16.0102 16.25 14.7676V13.7676C16.25 13.3534 16.5858 13.0176 17 13.0176C17.4142 13.0176 17.75 13.3534 17.75 13.7676V14.7676C17.75 16.8386 16.0711 18.5176 14 18.5176H6C3.92893 18.5176 2.25 16.8386 2.25 14.7676V13.7676C2.25 13.3534 2.58579 13.0176 3 13.0176Z"
          fill="black"
        />
      </svg>
    </Icon>
  );
}
